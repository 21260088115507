<template>
  <div ref="login" class="login-wrapper">
    <div class="login">
      <div class="title">欢迎登录！</div>
      <el-input class="username" placeholder="请输用户名" v-model="username"></el-input>
      <el-input class="password" placeholder="请输密码" v-model="password" show-password  @keyup.enter.native="login()"></el-input>
      <div class="btn" @click="login">立即登录</div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { login } from '@/api/login'
export default {
  data() {
    return {
      username: '',
      password: '',
    }
  },
  mounted() {
    this.setScale()
  },
  methods: {
    setScale() {
      this.scale = this.getScale()
      if (this.$refs.login) {
        this.$refs.login.style.setProperty('--scale', this.scale)
      }
    },
    getScale() {
      let wh = window.innerHeight / 1080
      let ww = window.innerWidth / 1920
      return ww < wh ? ww : wh
    },
    login() {
      //               window.sessionStorage.setItem('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkMDU2MmJlNmEzMTY0MWVkOGYxZTZmY2E0MTk5YjViNyIsInVzZXJuYW1lIjoicmVwb3J0QWRtaW4ifQ.mumfc38gJ7OZJf_XlQrj5h2p-ZFuwppJVwSqvg1SsyI')
      login({
        loginName: this.username,
        password: this.password,
        projectCode: 'dataReporte',
      }).then((res) => {
        if (res.type === 'success') {
          window.sessionStorage.setItem('token', res.data.token)
          var id = (res?.data?.childdata?.filter(item => item.powertype == '产业')?.map(item => item.dataid)??[]).toString()
          window.sessionStorage.setItem('industryId', '2862999118ed4dc296b42fe8fecdd301')
          this.$router.push({
            path: '/index',
          })
        }
      }).catch(err => {

        this.$message.error(err.content)
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.login-wrapper {
  width: 100vw;
  height: 100vh;
  background-image: url('./sys-name.png'), url('./login-bg.png');
  background-repeat: no-repeat;
  background-size: 41.6vw 5.7vh, 100% 100%;
  background-position: center 17%, center center;
  display: flex;
  justify-content: center;
  align-items: center;
  .login {
    width: 477px;
    height: 438px;
    background: rgba(1, 13, 76, 0.65);
    transform: scale(var(--scale));
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 50px;
    box-sizing: border-box;
    .title {
      font-size: 29px;
      color: #68d2fe;
      margin-bottom: 48px;
      align-self: flex-start;
    }
    ::v-deep .el-input {
      width: 371px;
      border-radius: 8px;
      position: relative;
      .el-input__inner {
        height: 58px;
        line-height: 58px;
        background: rgba(24, 144, 255, 0.45);
        border: 0;
        font-size: 16px;
        text-indent: 50px;
        color: #fff;
      }
    }
    .username {
      margin-bottom: 35px;
    }
    .password {
      margin-bottom: 50px;
    }
    .username:before,
    .password:before {
      position: absolute;
      content: '';
      display: inline-block;
      width: 27px;
      height: 32px;
      left: 20px;
      top: 13px;
    }
    .username:before {
      background: url('./icon-user.png') no-repeat center/cover;
    }
    .password:before {
      background: url('./icon-pwd.png') no-repeat center/cover;
    }
    .btn {
      width: 348px;
      height: 58px;
      line-height: 58px;
      background: linear-gradient(0deg, #5084fc, #46aeff);
      box-shadow: 0px 5px 21px 0px rgba(55, 120, 238, 0.56);
      border-radius: 29px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
      text-align: center;
    }
  }
}
</style>
